import BaseModel from '@/api/models/BaseModel';
import { PrivateAppDataRowInterface } from '@/domains/pd-admin/types/administration/private-apps/private-apps';

class PrivateAppsResponseModel extends BaseModel {
  public private_apps: PrivateAppDataRowInterface[];
  public total_private_apps: number;
  constructor(arg: any) {
    super(arg);
    this.private_apps = arg.private_apps;
    this.total_private_apps = arg.total_private_apps;
  }
}

export default PrivateAppsResponseModel;
