import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import router from '@/router';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidLoader,
  ZidButton,
  ZidPagination,
  ZidTable,
  ZidTableRow,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRowGroup,
} from '@zidsa/ui';
import ExcelIcon from '@/assets/icons/domains/partnership-admin/ExcelIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import usePrivateApps from '@/domains/pd-admin/helpers/administration/private-apps/usePrivateApps';
import { getPrivateApps } from '@/domains/pd-admin/api/administration/private-apps/private-apps';
import { PrivateAppDataRowInterface } from '@/domains/pd-admin/types/administration/private-apps/private-apps';
import ErrorModel from '@/api/models/ErrorModel';
import { METABASE_PRIVATE_APPS_TABLE_WITH_FILTERS } from '@/helpers/constantLinks';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ExcelIcon,
    SearchIcon,
    EditIcon,
    ZidLoader,
    ZidButton,
    ZidPagination,
    ZidTable,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    ZidTableRowGroup,
  },
  setup() {
    const MetabaseFiltersLink = ref(METABASE_PRIVATE_APPS_TABLE_WITH_FILTERS);

    const {
      initialListLastAppID,
      lastAppID,
      originalApps,
      filteredApps,
      displayedApps,
      currentFilterRange,
      isFilterApplied,
      isLoading,
      totalAppsSize,
      previousBtnNavigation,
      isNextBtnDisabled,
      onPreviousClick,
      onNextClick,
    } = usePrivateApps();

    onBeforeMount(async () => {
      isLoading.value = true;
      const response = await getPrivateApps(lastAppID.value);
      if (response instanceof ErrorModel || !response.private_apps) {
        isLoading.value = false;
        return;
      }
      originalApps.list = response.private_apps;
      initialListLastAppID.value = originalApps.list[originalApps.list.length - 1].id;
      lastAppID.value = originalApps.list[originalApps.list.length - 1].id;
      const initialTableRecordCount = response.private_apps.length >= 10 ? 10 : response.private_apps.length;
      totalAppsSize.value = response.total_private_apps;
      displayedApps.list = response.private_apps.slice(0, initialTableRecordCount);
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = initialTableRecordCount;
      isLoading.value = false;
    });

    const onEditClicked = (application: PrivateAppDataRowInterface) => {
      if (application.app_type === ApplicationTypeProperty.OAuth) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_OAuth,
            params: {
              id: `${application.app_id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      } else if (application.app_type === ApplicationTypeProperty.Javascript) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_JS,
            params: {
              id: `${application.app_id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      }
    };

    return {
      MetabaseFiltersLink,
      initialListLastAppID,
      lastAppID,
      originalApps,
      filteredApps,
      displayedApps,
      currentFilterRange,
      isFilterApplied,
      isLoading,
      totalAppsSize,
      previousBtnNavigation,
      isNextBtnDisabled,
      ApplicationTypeProperty,
      onPreviousClick,
      onNextClick,
      onEditClicked,
    };
  },
});
