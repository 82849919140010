import { reactive, watch, ref, computed } from '@vue/composition-api';
import {
  PrevBtnNavigationInterface,
  PrivateAppDataRowInterface,
} from '@/domains/pd-admin/types/administration/private-apps/private-apps';
import { getPrivateApps } from '@/domains/pd-admin/api/administration/private-apps/private-apps';
import ErrorModel from '@/api/models/ErrorModel';

const usePrivateApps = () => {
  const originalApps = reactive<Record<string, PrivateAppDataRowInterface[]>>({
    list: [],
  });

  const filteredApps = reactive<Record<string, PrivateAppDataRowInterface[]>>({
    list: [],
  });

  const displayedApps = reactive<Record<string, PrivateAppDataRowInterface[]>>({
    list: [],
  });

  const previousBtnNavigation = reactive<Record<string, PrevBtnNavigationInterface[]>>({
    list: [],
  });

  const currentFilterRange = reactive<any>({
    startIndex: -1,
    endIndex: -1,
  });

  const INITIAL_LIST_ID = -1;

  const initialListLastAppID = ref(INITIAL_LIST_ID);

  const lastAppID = ref(INITIAL_LIST_ID);

  const isFilterByStoreType = ref(false);

  const isFilterBySearch = ref(false);

  const isFilterApplied = ref(false);

  const isLoading = ref(false);

  const totalAppsSize = ref(0);

  watch(
    () => filteredApps,
    () => {
      applyPageRangeFilter();
    },
    { deep: true },
  );

  const fetchByFilters = async (appID: number) => {
    isLoading.value = true;

    const filterRes = await getPrivateApps(appID);
    if (filterRes instanceof ErrorModel || !filterRes.private_apps) {
      isLoading.value = false;
      return;
    }
    if (filterRes.private_apps.length) {
      originalApps.list = [...filterRes.private_apps];
      lastAppID.value = originalApps.list[originalApps.list.length - 1].id;
      totalAppsSize.value = filterRes.total_private_apps;
      filteredApps.list = [...filterRes.private_apps];
      displayedApps.list = [...filterRes.private_apps];
    } else {
      originalApps.list = [];
      lastAppID.value = INITIAL_LIST_ID;
      totalAppsSize.value = filterRes.total_private_apps;
      filteredApps.list = [];
      displayedApps.list = [];
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = 10;
    }
    isLoading.value = false;
  };

  const applyPageRangeFilter = () => {
    let newAppsListEndIndex;

    if (!isFilterApplied.value) {
      newAppsListEndIndex =
        originalApps.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : originalApps.list.length;
      displayedApps.list = originalApps.list.slice(currentFilterRange.startIndex, newAppsListEndIndex);
    } else {
      newAppsListEndIndex =
        filteredApps.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : filteredApps.list.length;
      displayedApps.list = filteredApps.list.slice(currentFilterRange.startIndex, newAppsListEndIndex);
    }
  };

  const onPreviousClick = async () => {
    let prevListFetcherID = 0;
    prevListFetcherID = previousBtnNavigation.list.pop()?.prevListFetcherID ?? -1;
    await fetchByFilters(prevListFetcherID);
    applyPageRangeFilter();
  };

  const onNextClick = async () => {
    if (initialListLastAppID.value === lastAppID.value) {
      previousBtnNavigation.list.push({
        prevListFetcherID: -1,
        prevListLastAppID: lastAppID.value,
      });
    } else {
      previousBtnNavigation.list.push({
        prevListFetcherID: previousBtnNavigation.list[previousBtnNavigation.list.length - 1].prevListLastAppID,
        prevListLastAppID: lastAppID.value,
      });
    }
    await fetchByFilters(lastAppID.value);
    applyPageRangeFilter();
  };

  const isNextBtnDisabled = computed(() => {
    return (
      displayedApps.list.length === 0 ||
      previousBtnNavigation.list.length * 10 + displayedApps.list.length >= totalAppsSize.value
    );
  });

  return {
    initialListLastAppID,
    lastAppID,
    originalApps,
    filteredApps,
    displayedApps,
    currentFilterRange,
    isFilterApplied,
    isLoading,
    totalAppsSize,
    previousBtnNavigation,
    isNextBtnDisabled,
    onPreviousClick,
    onNextClick,
  };
};

export default usePrivateApps;
