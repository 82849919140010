import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PrivateAppsResponseModel from '@/domains/pd-admin/models/administration/private-apps/private-apps-response-model';

const ENDPOINT_URL = '/market/admin';

export const getPrivateApps = (
  lastAppID: number,
  resultPerPage = 10,
): Promise<PrivateAppsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/private?id=${lastAppID}&limit=${resultPerPage}`)
    .then((response) => new PrivateAppsResponseModel(response))
    .catch((error) => error);
};
